import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '@shared/components';

@Component({
  selector: 'mydendra-not-permitted',
  templateUrl: './not-permitted.component.html',
  styleUrl: './not-permitted.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, SharedComponentsModule],
})
export class NotPermittedComponent {}
