import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import {
  HttpMethod,
  NG_ENTITY_SERVICE_CONFIG,
} from '@datorama/akita-ng-entity-service';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '@environment';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { TraceService } from '@sentry/angular';
import { AuthInterceptor } from '@services/auth.interceptor';
import { SharedComponentsModule } from '@shared/components';
import { provideSentryErrorHandler } from '@shared/sentry';
import {
  getPopoverConfig,
  getTooltipConfig,
} from '@shared/services/popover-and-tooltip-config.service';
import { UiThemingModule } from '@shared/services/ui-theming/ui-theming.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverConfig, PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { CookieService } from 'ngx-cookie-service';
import { NgxEchartsModule } from 'ngx-echarts';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { ComponentsModule } from './app/components/components.module';
import { SKAIFormlyConfig } from './app/formly/config';
import { FormlyComponentsModule } from './app/formly/types';
import { HomeModule } from './app/home/home.module';
import { PlanningModule } from './app/planning/planning.module';
import { SettingsModule } from './app/settings/settings.module';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      ReactiveFormsModule,
      ToastrModule.forRoot({
        preventDuplicates: true,
        positionClass: 'toast-bottom-right',
        closeButton: true,
      }),
      BsDropdownModule.forRoot(),
      CollapseModule.forRoot(),
      ModalModule.forRoot(),
      PopoverModule.forRoot(),
      TooltipModule.forRoot(),
      NgxEchartsModule.forRoot({ echarts: () => import('./app/skai-echarts') }),
      ComponentsModule,
      SettingsModule,
      HomeModule,
      PlanningModule,
      environment.production ? [] : AkitaNgDevtools.forRoot(),
      AkitaNgRouterStoreModule,
      FormlyModule.forRoot(SKAIFormlyConfig),
      FormlySelectModule,
      FormlyBootstrapModule,
      NgSelectModule,
      NgxMapLibreGLModule,
      SharedComponentsModule,
      UiThemingModule,
      FormlyComponentsModule,
    ),
    CookieService,
    provideSentryErrorHandler(),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: {
        baseUrl: '/api',
        httpMethods: {
          PUT: HttpMethod.PATCH,
        },
      },
    },
    { provide: TraceService, deps: [Router] },
    { provide: PopoverConfig, useFactory: getPopoverConfig },
    { provide: TooltipConfig, useFactory: getTooltipConfig },
    provideAnimations(),
  ],
}).catch(err => console.error(err));
