import { ErrorHandler, Provider } from '@angular/core';
import { environment } from '@environment';
import * as Sentry from '@sentry/angular';

export const provideSentryErrorHandler = (): Provider => {
  const errorHandler = environment.sentry
    ? Sentry.createErrorHandler()
    : new ErrorHandler();
  return { provide: ErrorHandler, useValue: errorHandler };
};
