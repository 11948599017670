import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AnalyticsEntity } from '@dendra/entity-analytics';
import { AuthInteractor } from '@dendra/interactor-auth';
import { CheckUpdateService } from '@services/check-update.service';
import { HijackService } from '@services/hijack.service';
import { FlagService } from '@services/states/flags';

@Component({
  selector: 'skai-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule],
})
export class AppComponent implements OnInit {
  private checkUpdateService = inject(CheckUpdateService);
  private hijackService = inject(HijackService);

  private analyticsService = inject(AnalyticsEntity);
  private authInteractor = inject(AuthInteractor);
  private flagService = inject(FlagService);

  ngOnInit() {
    this.authInteractor.init();
    this.analyticsService.init();
    this.flagService.init();
    this.checkUpdateService.setup();
    this.hijackService.setup();
  }
}
