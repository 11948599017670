import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
import { switchMap, tap, retry, takeWhile, filter } from 'rxjs/operators';

export const CHECK_INTERVAL = 3 * 60 * 1000; // 3 minutes

type MotD = { message: string; type: string };
type VersionInfo = { version_tag: string };

@Injectable({
  providedIn: 'root',
})
export class CheckUpdateService {
  private toastr = inject(ToastrService);
  private http = inject(HttpClient);

  private version_url = '/api/platform-version/';
  private motd_url = '/api/motd/';
  private toastr_options = { disableTimeOut: true, tapToDismiss: false };
  reloadRequired = false;
  _etag?: string;
  _motd_toast: number | null;

  // Checks whether the angular app has been modified
  check_stream = timer(0, CHECK_INTERVAL).pipe(
    takeWhile(() => !this.reloadRequired),
    filter(() => document.visibilityState == 'visible'),
    switchMap(() => this.checkForAngularChanges()),
  );
  // Checks for MOTD
  motd_stream = timer(0, CHECK_INTERVAL).pipe(
    filter(() => document.visibilityState == 'visible'),
    switchMap(() => this.checkMotd()),
  );

  setup() {
    this.check_stream.subscribe();
    this.motd_stream.subscribe();
  }

  checkMotd() {
    // Checks for MOTD from the server, and if there is one, displays it in a toast
    return this.http.get<MotD>(this.motd_url).pipe(
      tap(({ message, type }) => this.showMotd(message, type)),
      retry({ delay: CHECK_INTERVAL }),
    );
  }

  showMotd(message: string, type: string) {
    if (!message) {
      this.closeMotdToast();
      return;
    }

    type = this.getToastrType(type);
    const toastID = this.toastr[type](
      message,
      null,
      this.toastr_options,
    ).toastId;
    if (toastID !== this._motd_toast) {
      this.closeMotdToast();
    }
    this._motd_toast = toastID;
  }

  getToastrType(type: string) {
    if (type === 'danger') {
      return 'error';
    }
    if (!['success', 'info', 'warning', 'error'].includes(type)) {
      return 'show';
    }
    return type;
  }

  closeMotdToast() {
    if (this._motd_toast) {
      this.toastr.remove(this._motd_toast);
      this._motd_toast = null;
    }
  }

  checkForAngularChanges() {
    // Checks if the platform version has changed.
    // If it has, show a notification to prompt the user to reload the page.
    return this.http
      .get<VersionInfo>(this.version_url)
      .pipe(tap(info => this.notifyOfUpdate(info.version_tag)));
  }

  notifyOfUpdate(version_tag: string) {
    if (this._etag && version_tag && this._etag !== version_tag) {
      this.reloadRequired = true;
      this.toastr
        .info(
          'New update available. Please click here to refresh the page.',
          null,
          this.toastr_options,
        )
        .onTap.subscribe(() => window.location.reload());
    }
    if (!this._etag) {
      this._etag = version_tag;
    }
  }
}
