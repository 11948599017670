import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  FieldArrayType,
  FieldType,
  FieldWrapper,
  FormlyModule,
} from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { SharedComponentsModule } from '@shared/components';

@Component({
  selector: 'skai-formly-field-file',
  template: `
    <input
      type="file"
      [formControl]="$any(formControl)"
      [formlyAttributes]="field"
    />
  `,
  imports: [FormlyModule, ReactiveFormsModule],
})
export class FileTypeComponent extends FieldType {}

@Component({
  selector: 'skai-formly-array-type',
  template: `
    <div class="mb-3">
      @if (to.label) {
        <legend>{{ to.label }}</legend>
      }
      @if (to.description) {
        <p>{{ to.description }}</p>
      }

      @if (showError && formControl.errors) {
        <div class="alert alert-danger" role="alert">
          <formly-validation-message
            [field]="field"
          ></formly-validation-message>
        </div>
      }

      @for (field of field.fieldGroup; track field; let i = $index) {
        <div class="row">
          <formly-field class="col-10" [field]="field"></formly-field>
          <div class="col-2 text-end">
            <button class="btn btn-danger" type="button" (click)="remove(i)">
              -
            </button>
          </div>
        </div>
      }

      <div class="d-flex flex-row-reverse">
        <button class="btn btn-primary" type="button" (click)="add()">+</button>
      </div>
    </div>
  `,
  imports: [FormlyModule],
})
export class ArrayTypeComponent extends FieldArrayType {}

@Component({
  selector: 'skai-formly-object-type',
  template: `
    <div class="mb-3">
      @if (to.label) {
        <legend>{{ to.label }}</legend>
      }
      @if (to.description) {
        <p>{{ to.description }}</p>
      }
      @if (showError && formControl.errors) {
        <div class="alert alert-danger" role="alert">
          <formly-validation-message
            [field]="field"
          ></formly-validation-message>
        </div>
      }
      @for (f of field.fieldGroup; track f) {
        <formly-field [field]="f"></formly-field>
      }
    </div>
  `,
  imports: [FormlyModule],
})
export class ObjectTypeComponent extends FieldType {}

@Component({
  selector: 'skai-formly-null-type',
  template: '',
})
export class NullTypeComponent extends FieldType {}

@Component({
  selector: 'mydendra-autosuggest-input',
  template: `
    <input
      [attr.list]="key + '_options'"
      [formControl]="$any(formControl)"
      [class.is-invalid]="showError"
      [formlyAttributes]="field"
      class="form-control"
    />
    @if (to.options | formlySelectOptions: field | async; as opts) {
      <datalist [id]="key + '_options'">
        @for (o of opts; track o) {
          <option [value]="o.label"></option>
        }
      </datalist>
    }
  `,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlySelectModule,
  ],
})
export class AutoSuggestInputComponent extends FieldType {}

@Component({
  selector: 'mydendra-button-type',
  template: `
    <mydendra-button
      [type]="$any(to).type"
      [icon]="to.icon"
      [icon_position]="to.icon_position"
      [text]="to.text"
      [button_type]="to.button_type"
      (click)="onClick($event)"
      [disabled]="to.disabled"
    ></mydendra-button>
  `,
  imports: [SharedComponentsModule],
})
export class ButtonTypeComponent extends FieldType {
  onClick($event) {
    if (this.to.onClick) {
      this.to.onClick($event);
    }
  }
}

@Component({
  selector: 'mydendra-dropdown-type',
  template: `
    <mydendra-dropdown
      [options]="to.options"
      [disabled]="to.disabled"
      [readOnly]="to.readOnly"
      [initialValue]="to.initialValue"
      (selectValue)="onSelect($event)"
    >
    </mydendra-dropdown>
  `,
  imports: [SharedComponentsModule],
})
export class DropdownTypeComponent extends FieldType {
  onSelect($event) {
    this.to.dropdownValueChanged($event);
  }
}

@Component({
  selector: 'mydendra-toggle-type',
  template: `
    <mydendra-toggle-switch
      [label]="to.label"
      [disabled]="to.disabled"
      [read_only]="to.read_only"
      (toggled)="onToggle($event)"
      [value]="to.defaultValue"
    ></mydendra-toggle-switch>
  `,
  imports: [SharedComponentsModule],
})
export class ToggleTypeComponent extends FieldType {
  onToggle($event) {
    this.to.handleToggle($event);
  }
}

@Component({
  selector: 'skai-formly-wrapper-form-field',
  template: `
    <div class="mb-m row" [class.has-error]="showError">
      @if (to.label && to.hideLabel !== true) {
        <label
          class="col-sm-4 col-form-label"
          [attr.for]="id"
          [class.font-weight-bold]="to.required"
        >
          {{ to.label }}
          @if (to.required && to.hideRequiredMarker !== true) {
            <span>*</span>
          }
          @if (showError) {
            <div class="invalid-feedback" [style.display]="'block'">
              <formly-validation-message
                [field]="field"
              ></formly-validation-message>
            </div>
          }
        </label>
      }

      <div class="col-sm-8">
        <ng-template #fieldComponent></ng-template>
        @if (to.description) {
          <small class="form-text text-muted">{{ to.description }}</small>
        }
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormlyModule],
})
export class FormlyWrapperFormFieldComponent extends FieldWrapper {}

@Component({
  selector: 'mydendra-formly-wrapper-form-field',
  template: `
    <div class="mb-m" [class.has-error]="showError">
      <div class="d-flex justify-content-between">
        @if (to.label && to.hideLabel !== true) {
          <label class="form-label flex-1 body" [attr.for]="id">
            {{ to.label }}
            @if (to.required && to.hideRequiredMarker !== true) {
              <span>*</span>
            }
          </label>
        }
        @if (showError) {
          <div
            class="invalid-feedback"
            [style.display]="'block'"
            [style.width]="'auto'"
          >
            <formly-validation-message
              [field]="field"
            ></formly-validation-message>
          </div>
        }
      </div>

      <ng-template #fieldComponent></ng-template>
      @if (to.description) {
        <small class="form-text text-muted">{{ to.description }}</small>
      }
    </div>
  `,
  styles: ['label { color: var(--color-aa-secondary-grey) }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormlyModule],
})
export class MyDendraWrapperFormFieldComponent extends FieldWrapper {}

@Component({
  selector: 'mydendra-formly-container-field-wrapper',
  template: `
    <mydendra-container class="{{ to.additionalClasses }}">
      @if (to.title) {
        <h2 class="heading-4 mb-l">{{ to.title }}</h2>
      }
      <ng-container #fieldComponent></ng-container>
    </mydendra-container>
  `,
  imports: [SharedComponentsModule],
})
export class MyDendraContainerFieldComponent extends FieldWrapper {}

export const EXTRA_FORMLY_COMPONENTS = [
  FileTypeComponent,
  ArrayTypeComponent,
  ObjectTypeComponent,
  NullTypeComponent,
  FormlyWrapperFormFieldComponent,
  MyDendraWrapperFormFieldComponent,
  AutoSuggestInputComponent,
  ButtonTypeComponent,
  ToggleTypeComponent,
  MyDendraContainerFieldComponent,
  DropdownTypeComponent,
];

@NgModule({
  imports: [...EXTRA_FORMLY_COMPONENTS],
})
export class FormlyComponentsModule {}
