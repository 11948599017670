import { AbstractControl } from '@angular/forms';

export const startBeforeDueDateValidator = (control: AbstractControl) => {
  const { due_date, start_date } = control.value;
  if (!due_date || !start_date) {
    return null;
  }

  if (new Date(due_date) > new Date(start_date)) {
    return null;
  }

  return {
    startBeforeDueDate: { message: 'Start date must be before due date' },
  };
};

export const notEmptyValidator = (control: AbstractControl) => {
  const trimmed = control.value?.trim();

  if (trimmed === '') {
    return { notEmpty: { message: 'Value cannot be empty' } };
  }
};
