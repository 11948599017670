import { defaultValidationMessages } from '@dendra/utils';
import { ConfigOption } from '@ngx-formly/core';
import {
  NullTypeComponent,
  ArrayTypeComponent,
  ObjectTypeComponent,
  FormlyWrapperFormFieldComponent,
  AutoSuggestInputComponent,
  MyDendraWrapperFormFieldComponent,
  ButtonTypeComponent,
  FileTypeComponent,
  MyDendraContainerFieldComponent,
  DropdownTypeComponent,
  ToggleTypeComponent,
} from './types';
import { startBeforeDueDateValidator, notEmptyValidator } from './validations';

export const SKAIFormlyConfig: ConfigOption = {
  extras: {
    lazyRender: true,
  },
  types: [
    { name: 'string', extends: 'input', wrappers: ['skai-form-field'] },
    {
      name: 'number',
      extends: 'input',
      defaultOptions: {
        templateOptions: {
          type: 'number',
        },
      },
      wrappers: ['skai-form-field'],
    },
    {
      name: 'integer',
      extends: 'input',
      defaultOptions: {
        templateOptions: {
          type: 'number',
        },
      },
      wrappers: ['skai-form-field'],
    },
    {
      name: 'datepicker',
      extends: 'input',
      defaultOptions: {
        templateOptions: {
          type: 'date',
        },
      },
    },
    { name: 'radio', wrappers: ['skai-form-field'] },
    { name: 'boolean', extends: 'checkbox' },
    { name: 'null', component: NullTypeComponent, wrappers: ['form-field'] },
    {
      name: 'file',
      component: FileTypeComponent,
      wrappers: ['skai-form-field'],
    },
    { name: 'array', component: ArrayTypeComponent },
    { name: 'object', component: ObjectTypeComponent },
    {
      name: 'autosuggest',
      component: AutoSuggestInputComponent,
      wrappers: ['mydendra-form-field'],
    },
    { name: 'button', component: ButtonTypeComponent },
    { name: 'dropdown', component: DropdownTypeComponent },
    { name: 'toggle', component: ToggleTypeComponent },
  ],
  validators: [
    { name: 'startBeforeDueDate', validation: startBeforeDueDateValidator },
    { name: 'notEmpty', validation: notEmptyValidator },
  ],
  validationMessages: defaultValidationMessages,
  wrappers: [
    { name: 'skai-form-field', component: FormlyWrapperFormFieldComponent },
    {
      name: 'mydendra-form-field',
      component: MyDendraWrapperFormFieldComponent,
    },
    { name: 'mydendra-container', component: MyDendraContainerFieldComponent },
  ],
};
