import { Injectable, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { filter } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environment';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private router = inject(Router);
  private cookieService = inject(CookieService);
  private toastService = inject(ToastrService);

  last_url: string;

  constructor() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const last_url = this.router.routerState.snapshot.url;
        /* istanbul ignore else  */
        if (last_url) {
          this.last_url = last_url;
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const csrftoken = this.cookieService.get(environment.csrftoken_name);
    request = request.clone({
      setHeaders: {
        'X-CSRFToken': csrftoken,
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 403) {
          this.toastService.error(
            'You are not authorized to perform this action',
          );
        }
        return throwError(() => response);
      }),
    );
  }
}
