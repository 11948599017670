import { PopoverConfig } from 'ngx-bootstrap/popover';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';

export function getPopoverConfig(): PopoverConfig {
  return Object.assign(new PopoverConfig(), {
    container: 'body',
    triggers: 'mouseenter:mouseleave',
  });
}

export function getTooltipConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    container: 'body',
  });
}
