import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleChoices } from '@dendra/entity-auth';
import { hasFlagGuard } from '@services/states/flags';
import {
  skipLocationChangeGuard,
  loginRequiredGuard,
  orgMembershipRequiredGuard,
  rolePermissionGuard,
} from '@shared/guards';
import { AppContainerComponent } from './components/app-container/app-container.component';
import { NotExistsComponent } from './global/not-exists/not-exists.component';
import { NotPermittedComponent } from './global/not-permitted/not-permitted.component';

const CUSTOMER_FACING_GUARDS = [
  loginRequiredGuard,
  orgMembershipRequiredGuard,
  rolePermissionGuard,
];

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'maps' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  { path: 'analysis', redirectTo: 'insights' },
  { path: 'portal', redirectTo: 'maps' },
  {
    path: '',
    component: AppContainerComponent,
    children: [
      {
        path: 'home',
        canActivate: [...CUSTOMER_FACING_GUARDS, hasFlagGuard],
        data: { flag: 'home-app' },
        loadChildren: () =>
          import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'maps',
        data: { has_access: RoleChoices.gis_portal },
        canActivate: CUSTOMER_FACING_GUARDS,
        loadChildren: () =>
          import('./portal/portal.module').then(m => m.PortalModule),
      },
      {
        path: 'plan',
        canActivate: [...CUSTOMER_FACING_GUARDS, hasFlagGuard],
        data: { flag: 'planning-app' },
        loadChildren: () =>
          import('./planning/planning.module').then(m => m.PlanningModule),
        title: 'Planning',
      },
      {
        path: 'monitor',
        canActivate: [...CUSTOMER_FACING_GUARDS, hasFlagGuard],
        data: { flag: 'monitoring-app' },
        loadChildren: () =>
          import('./monitoring/monitoring.module').then(
            m => m.MonitoringModule,
          ),
        title: 'Monitoring',
      },
      {
        path: 'intervention',
        canActivate: [...CUSTOMER_FACING_GUARDS, hasFlagGuard],
        data: { flag: 'intervention-app' },
        loadChildren: () =>
          import('./intervention/intervention.module').then(
            m => m.InterventionModule,
          ),
      },
      {
        path: 'activity',
        canActivate: [...CUSTOMER_FACING_GUARDS, hasFlagGuard],
        data: { flag: 'activity' },
        loadChildren: () =>
          import('./events/events.module').then(m => m.EventsModule),
      },
      {
        path: 'settings',
        data: { has_access: RoleChoices.settings },
        canActivate: CUSTOMER_FACING_GUARDS,
        loadChildren: () =>
          import('./settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'weeds',
        data: { has_access: RoleChoices.weed_management },
        canActivate: CUSTOMER_FACING_GUARDS,
        loadChildren: () =>
          import('./weed-management/weed-management.module').then(
            m => m.WeedManagementModule,
          ),
      },
      {
        path: 'insights',
        canActivate: [loginRequiredGuard], // Accessible without org membership
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      // Lazy load a few components that have a lot of static content
      {
        path: 'terms',
        loadComponent: () =>
          import('./global/terms/terms.component').then(c => c.TermsComponent),
        title: 'Terms & Conditions',
      },
      {
        path: 'privacy',
        loadComponent: () =>
          import('./global/privacy/privacy.component').then(
            c => c.PrivacyPolicyComponent,
          ),
        title: 'Privacy Policy',
      },
      {
        path: 'cookies',
        loadComponent: () =>
          import('./global/cookies/cookies.component').then(
            c => c.CookiePolicyComponent,
          ),
        title: 'Cookie Policy',
      },
      {
        path: 'account',
        canActivate: [loginRequiredGuard],
        loadChildren: () =>
          import('@shared/modules/account/account.module').then(
            m => m.AccountModule,
          ),
      },
      {
        path: 'not-permitted',
        component: NotPermittedComponent,
        canActivate: [skipLocationChangeGuard],
      },
      {
        path: 'not-exists',
        component: NotExistsComponent,
        canActivate: [skipLocationChangeGuard],
      },
      { path: '**', component: NotExistsComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
